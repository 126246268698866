import axios from "axios";
import React, { useState, useRef, useContext } from "react";
import { FaTimes, FaLock, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import useOutsideClick from "../hooks/useOutsideClick";
import { AppContext } from "../AppContext";

export default function AddModal() {
  const [formData, setFormData] = useState({
    name: "",
  });
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setShow(false));
  const { getUsersPointsData } = useContext(AppContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.name === "") return;
    await toast.promise(axios.post("/api/points", formData), {
      pending: "Adding...",
      success: "Added successfully",
      error: "Something went wrong!",
    });
    getUsersPointsData();
    setShow(false);
  };

  return (
    <>
      <button className="btn" onClick={() => setShow(true)}>
        <FaPlus />
        <span>Add User</span>
      </button>

      {show && (
        <div className="modal">
          <div className="modal-content" ref={ref}>
            <div className="modal-header">
              <h2 className="modal-title">Add User</h2>
              <button className="modal-close btn" onClick={() => setShow(!show)}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-body">
              <form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="btn" onClick={handleSubmit}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
