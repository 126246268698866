import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./sass/main.scss";
import { AppContextProvider } from "./AppContext";
import axios from "axios";

axios.defaults.baseURL = "https://charity-points-board-api.herokuapp.com";
// axios.defaults.baseURL = "http://localhost:3030";
axios.defaults.headers["authorization"] = "Bearer " + localStorage.getItem("token");

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
