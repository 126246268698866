import React, { useContext, useEffect } from "react";
import Item from "./component/Item";
import Modal from "./component/Modal";
import { FaCog, FaCogs, FaExternalLinkAlt, FaLock, FaSortAmountDown } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import LoginModal from "./component/LoginModal";
import SettingsModal from "./component/SettingsModal";
import AddModal from "./component/AddModal";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "./AppContext";
import axios from "axios";
import AreYouSure from "./component/AreYouSureModal";
import { decodeToken } from "react-jwt";
import MultiEditModal from "./component/MultiEditModel";
import Loader from "./component/Loader";

export default function App() {
  const { state, logout, odrer, getUsersPointsData } = useContext(AppContext);

  const resetAllPoints = async (e) => {
    await toast.promise(axios.patch("/api/points/reset"), {
      pending: "Reseting...",
      success: "Reset done!",
      error: "Something went wrong!",
    });
    getUsersPointsData();
  };

  return (
    <div>
      <header>
        {state.isLoggedIn ? <h1>Hello, {decodeToken(state.token).name}</h1> : <h1>Welcome to Charity Points Board</h1>}
        <div className="btns">
          {state.isLoggedIn && (
            <p>
              <span style={{ fontWeight: "bold" }}>{state.selected.length}</span> Item Selected
            </p>
          )}
          <button className="btn" onClick={odrer}>
            <FaSortAmountDown />
          </button>
          {!state.isLoggedIn && (
            <>
              <LoginModal />
            </>
          )}
          {state.isLoggedIn && (
            <>
              {state.selected.length ? <MultiEditModal /> : null}
              <SettingsModal />
              <AddModal />
              <AreYouSure triggerText={"00"} onConfirm={resetAllPoints} />
              <AreYouSure
                triggerText={
                  <>
                    <FaExternalLinkAlt />
                    <span>Logout</span>
                  </>
                }
                onConfirm={logout}
              />
            </>
          )}
        </div>
      </header>
      <div className="container">
        <ul className="list">
          {state.loading ? (
            <Loader />
          ) : (
            state.users.map((el) => (
              <Item
                key={el._id}
                id={el._id}
                name={el.name}
                charity={el.charity}
                share={el.share}
                likes={el.likes}
                story={el.story}
                scout={el.scout}
              />
            ))
          )}
        </ul>
      </div>
      <ToastContainer />
    </div>
  );
}
