import axios from "axios";
import React, { useState, useRef, useContext } from "react";
import { FaTimes, FaLock, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import useOutsideClick from "../hooks/useOutsideClick";
import { AppContext } from "../AppContext";

export default function AreYouSure({ triggerText, onConfirm, span }) {
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setShow(false));

  const handleSubmit = async (e) => {
    e.preventDefault();
    onConfirm(e);
    setShow(false);
  };

  return (
    <>
      {span ? (
        <span onClick={() => setShow(true)}>{triggerText}</span>
      ) : (
        <button className="btn" onClick={() => setShow(true)}>
          {triggerText}
        </button>
      )}

      {show && (
        <div className="modal">
          <div className="modal-content" ref={ref}>
            <div className="modal-header">
              <h2 className="modal-title">Are You Sure?</h2>
            </div>
            <div className="modal-body">If you are sure about that. Just Confirm it</div>
            <div className="modal-footer">
              <button className="btn" onClick={handleSubmit}>
                Confirm
              </button>
              <button className="modal-close btn" onClick={() => setShow(!show)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
