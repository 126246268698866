import axios from "axios";
import React, { useState, useRef, useEffect, useContext } from "react";
import { FaTimes, FaLock, FaCog, FaMinus, FaPlus, FaTrashAlt } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { AppContext } from "../AppContext";
import useOutsideClick from "../hooks/useOutsideClick";
import { swal } from "sweetalert2";
import AreYouSure from "./AreYouSureModal";

export default function MultiEditModal() {
  const [formData, setFormData] = useState({
    charity: 0,
    share: 0,
    likes: 0,
    story: 0,
    scout: 0,
  });
  const [show, setShow] = useState(false);
  const [settings, setSettings] = useState({
    charity: 1,
    share: 1,
    likes: 1,
    story: 1,
    scout: 1,
  });
  const ref = useRef();
  useOutsideClick(ref, () => setShow(false));
  const { getUsersPointsData, state } = useContext(AppContext);

  const handleChange = (e, prop, oper) => {
    e.preventDefault();
    if (oper === "inc") {
      setFormData({ ...formData, [prop]: formData[prop] + settings[prop] });
    } else {
      if (formData[prop] > 1) {
        setFormData({ ...formData, [prop]: formData[prop] - settings[prop] });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await toast.promise(axios.post(`/api/points/increment`, { data: formData, ids: state.selected }), {
      pending: "Saving data...",
      success: "Saved successfully!",
      error: "Something went wrong!",
    });
    getUsersPointsData();
    setShow(false);
  };

  useEffect(async () => {
    if (!show) {
      setFormData({
        charity: 0,
        share: 0,
        likes: 0,
        story: 0,
        scout: 0,
      });
      return null;
    }
    const resUser = await axios.get("/api/user/me");
    console.log(resUser.data);
    setSettings({
      charity: resUser.data.user.charity,
      share: resUser.data.user.share,
      likes: resUser.data.user.likes,
      story: resUser.data.user.story,
      scout: resUser.data.user.scout,
    });
  }, [show]);

  return (
    <>
      <button className="btn edit-btn" onClick={() => setShow(true)}>
        <FaEdit />
        <span>Edit Selected Items</span>
      </button>

      {show && (
        <div className="modal">
          <div className="modal-content" ref={ref}>
            <div className="modal-header">
              <h2 className="modal-title">Edit Selected Items ({state.selected.length})</h2>
              <button className="modal-close btn" onClick={() => setShow(!show)}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-body">
              <form className="form" onSubmit={handleSubmit}>
                <div className="form-group form-inline">
                  <label htmlFor="charity">Charity Points</label>
                  <div className="increment-decrement">
                    {/* <button onClick={(e) => handleChange(e, "charity", "dec")}>
                      <FaMinus />
                    </button> */}
                    <span>{formData.charity}</span>
                    <button onClick={(e) => handleChange(e, "charity", "inc")}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
                <div className="form-group  form-inline">
                  <label htmlFor="share">Share Points</label>
                  <div className="increment-decrement">
                    {/* <button onClick={(e) => handleChange(e, "share", "dec")}>
                      <FaMinus />
                    </button> */}
                    <span>{formData.share}</span>
                    <button onClick={(e) => handleChange(e, "share", "inc")}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="likes">Likes Points</label>
                  <div className="increment-decrement">
                    {/* <button onClick={(e) => handleChange(e, "likes", "dec")}>
                      <FaMinus />
                    </button> */}
                    <span>{formData.likes}</span>
                    <button onClick={(e) => handleChange(e, "likes", "inc")}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="story">Story Points</label>
                  <div className="increment-decrement">
                    {/* <button onClick={(e) => handleChange(e, "story", "dec")}>
                      <FaMinus />
                    </button> */}
                    <span>{formData.story}</span>
                    <button onClick={(e) => handleChange(e, "story", "inc")}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="scout">Scout Points</label>
                  <div className="increment-decrement">
                    {/* <button onClick={(e) => handleChange(e, "scout", "dec")}>
                      <FaMinus />
                    </button> */}
                    <span>{formData.scout}</span>
                    <button onClick={(e) => handleChange(e, "scout", "inc")}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="btn" onClick={handleSubmit}>
                Apply Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
