import React, { useContext } from "react";
import { FaEdit } from "react-icons/fa";
import { AppContext } from "../AppContext";
import EditModal from "./EditModal";

export default function Item({ id, name, charity, share, likes, story, scout }) {
  const { state, addSelected, removeSelected } = useContext(AppContext);

  const handleSelect = () => {
    if (!state.isLoggedIn) return;
    if (state.selected.find((el) => el === id)) removeSelected(id);
    else addSelected(id);
  };

  return (
    <>
      <li className={`${state.selected.find((el) => el === id) ? "selected" : ""}`}>
        <h5 onClick={handleSelect}>
          <span className="name">
            {name} <span>{state.selected.find((el) => el === id) ? "(selected)" : ""}</span>
          </span>
          <div className="score">
            <span>
              <span className="small">charity</span>
              {charity}
            </span>
            <span>
              <span className="small">share</span>
              {share}
            </span>
            <span>
              <span className="small">likes</span>
              {likes}
            </span>
            <span>
              <span className="small">story</span>
              {story}
            </span>
            <span>
              <span className="small">scout</span>
              {scout}
            </span>
            <span>
              {/* <span className="small">total</span> */}
              {charity + share + likes + story + scout}
            </span>
          </div>
        </h5>

        {state.isLoggedIn & !state.selected.find((el) => el === id) ? <EditModal id={id} /> : null}
      </li>
    </>
  );
}
