import axios from "axios";
import React, { useEffect } from "react";

export const AppContext = React.createContext();

export const AppContextProvider = (props) => {
  const [state, setState] = React.useState({
    isLoggedIn: localStorage.getItem("token") ? true : false,
    token: localStorage.getItem("token"),
    users: [],
    selected: [],
    loading: true,
  });

  const login = (token) => {
    localStorage.setItem("token", token);
    setState({
      ...state,
      isLoggedIn: true,
      token,
    });

    axios.defaults.headers["authorization"] = "Bearer " + token;
  };

  const logout = (token) => {
    localStorage.removeItem("token");
    setState({
      ...state,
      isLoggedIn: false,
      token: null,
    });
    axios.defaults.headers["authorization"] = "Bearer " + null;
  };

  const getUsersPointsData = async () => {
    setState({
      ...state,
      loading: true,
    });
    const { data } = await axios.get("/api/points");
    setState({
      ...state,
      users: data,
      loading: false,
      selected: [],
    });
  };

  const odrer = () => {
    const ordered = state.users
      .map((el) => ({ ...el, total: el.charity + el.share + el.likes + el.story + el.scout }))
      .sort((a, b) => b.total - a.total);
    setState({
      ...state,
      users: ordered,
    });
  };

  const addSelected = (id) => {
    setState({
      ...state,
      selected: [...state.selected, id],
    });
  };

  const removeSelected = (id) => {
    setState({
      ...state,
      selected: state.selected.filter((el) => el !== id),
    });
  };

  useEffect(getUsersPointsData, []);

  return (
    <AppContext.Provider
      value={{
        state,
        login,
        logout,
        odrer,
        getUsersPointsData,
        addSelected,
        removeSelected,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
