import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { FaCog, FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import useOutsideClick from "../hooks/useOutsideClick";

export default function SettingsModal() {
  const [formData, setFormData] = useState({
    charity: 0,
    share: 0,
    likes: 0,
    story: 0,
    scout: 0,
  });
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setShow(false));

  const handleChange = (e, prop, oper) => {
    e.preventDefault();
    if (oper === "inc") {
      setFormData({ ...formData, [prop]: formData[prop] + 1 });
    } else {
      if (formData[prop] > 1) {
        setFormData({ ...formData, [prop]: formData[prop] - 1 });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.promise(axios.patch("/api/user/me", formData), {
      pending: "Saving...",
      success: "Changes saved!",
      error: "Something went wrong!",
    });
  };

  useEffect(async () => {
    const { data } = await axios.get("/api/user/me");
    setFormData({
      charity: data.user.charity || 0,
      share: data.user.share || 0,
      likes: data.user.likes || 0,
      story: data.user.story || 0,
      scout: data.user.scout || 0,
    });
  }, [show]);

  return (
    <>
      <button className="btn" onClick={() => setShow(true)}>
        <FaCog />
        <span>Settings</span>
      </button>

      {show && (
        <div className="modal">
          <div className="modal-content" ref={ref}>
            <div className="modal-header">
              <h2 className="modal-title">Settings</h2>
              <button className="modal-close btn" onClick={() => setShow(!show)}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-body">
              <form className="form" onSubmit={handleSubmit}>
                <div className="form-group form-inline">
                  <label htmlFor="charity">Charity Points</label>
                  <div className="increment-decrement">
                    <button onClick={(e) => handleChange(e, "charity", "dec")}>
                      <FaMinus />
                    </button>
                    <span>{formData.charity}</span>
                    <button onClick={(e) => handleChange(e, "charity", "inc")}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
                <div className="form-group  form-inline">
                  <label htmlFor="share">Share Points</label>
                  <div className="increment-decrement">
                    <button onClick={(e) => handleChange(e, "share", "dec")}>
                      <FaMinus />
                    </button>
                    <span>{formData.share}</span>
                    <button onClick={(e) => handleChange(e, "share", "inc")}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="likes">Likes Points</label>
                  <div className="increment-decrement">
                    <button onClick={(e) => handleChange(e, "likes", "dec")}>
                      <FaMinus />
                    </button>
                    <span>{formData.likes}</span>
                    <button onClick={(e) => handleChange(e, "likes", "inc")}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="story">Story Points</label>
                  <div className="increment-decrement">
                    <button onClick={(e) => handleChange(e, "story", "dec")}>
                      <FaMinus />
                    </button>
                    <span>{formData.story}</span>
                    <button onClick={(e) => handleChange(e, "story", "inc")}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="scout">Scout Points</label>
                  <div className="increment-decrement">
                    <button onClick={(e) => handleChange(e, "scout", "dec")}>
                      <FaMinus />
                    </button>
                    <span>{formData.scout}</span>
                    <button onClick={(e) => handleChange(e, "scout", "inc")}>
                      <FaPlus />
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="btn" onClick={handleSubmit}>
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
