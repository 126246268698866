import React, { useState, useRef, useContext } from "react";
import { FaTimes, FaLock } from "react-icons/fa";
import { toast } from "react-toastify";
import useOutsideClick from "../hooks/useOutsideClick";
import axios from "axios";
import { AppContext } from "../AppContext";

export default function LoginModal() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setShow(false));
  const { login } = useContext(AppContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.email === "" && formData.password === "") return;
    const { data } = await toast.promise(axios.post("/api/user/login", formData), {
      pending: "Login...",
      error: "Something went wrong!",
      success: "Login Successfully",
    });
    login(data.token);
    setShow(false);
  };

  return (
    <>
      <button className="btn" onClick={() => setShow(true)}>
        <FaLock />
        <span>Login</span>
      </button>

      {show && (
        <div className="modal">
          <div className="modal-content" ref={ref}>
            <div className="modal-header">
              <h2 className="modal-title">Login</h2>
              <button className="modal-close btn" onClick={() => setShow(!show)}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-body">
              <form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="text" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="btn" onClick={handleSubmit}>
                Login
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
